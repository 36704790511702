import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WebappComponent } from './webapp/webapp.component';
import { ImprintComponent } from './imprint/imprint.component';
import { PrivacyComponent } from './privacy/privacy.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'webapp',
    component: WebappComponent,
  },
  {
    path: 'impressum',
    component: ImprintComponent,
  },
  {
    path: 'datenschutz',
    component: PrivacyComponent,
  },
  {
    path: 'imprint',
    component: ImprintComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: ':shortUrl',
    component: WebappComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];
