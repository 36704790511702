import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, afterRender, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private title = inject(Title);
  private translate = inject(TranslateService);
  private selectedLang = environment.lang;

  constructor(@Inject(DOCUMENT) private document: Document) {
    afterRender(() => {
      this.localStoreLang();
    });
  }

  ngOnInit(): void {
    this.browserLang();
  }

  localStoreLang() {
    const lang = localStorage.getItem('lang');
    if (lang) {
      const useLang = RegExp(/en|de/).exec(lang) ? lang : environment.lang;
      this.selectedLang = useLang;
      this.translate.use(useLang);
      const html = this.document.getElementsByTagName('html')[0];
      html.setAttribute('lang', useLang);
      this.setMetaTags();
    }
  }

  browserLang() {
    // get browser language
    const browserLang = this.translate.getBrowserLang() ?? environment.lang;
    if (browserLang) {
      const useLang = RegExp(/en|de/).exec(browserLang)
        ? browserLang
        : environment.lang;
      this.selectedLang = useLang;
      this.translate.use(useLang);
      const html = this.document.getElementsByTagName('html')[0];
      html.setAttribute('lang', useLang);
    } else {
      this.translate.use(environment.lang);
    }
    this.setMetaTags();
  }

  setMetaTags() {
    if (this.selectedLang === 'de') {
      this.title.setTitle('Urlaubscountdown - Countdown zum Urlaub');
    }

    this.translate.get('meta.title').subscribe((res: string) => {
      this.title.setTitle(res);
    });

    this.translate.get('meta.description').subscribe((res: string) => {
      const meta = this.document.getElementsByTagName(
        'meta[name=description]'
      )[0];
      if (meta) {
        meta.setAttribute('content', res);
      }

      const meta2 = this.document.getElementsByTagName(
        'meta[name=DC.Description]'
      )[0];
      if (meta2) {
        meta2.setAttribute('content', res);
      }
    });

    // set lang tag in index.html
    const html = this.document.getElementsByTagName('html')[0];
    html.setAttribute('lang', this.selectedLang);
  }
}
