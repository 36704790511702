<div class="container" [ngClass]="{ subpage: !isHome }">
  <div class="row">
    <div class="col-md-3">
      <a href="/">
        <img
          src="./../../../assets/app-logo.jpg"
          alt="{{ 'meta.altTag' | translate }} Logo"
          class="src"
        />
      </a>
    </div>
    <div class="col-md-3 text-center align-middle text-middle">
      <nav class="nav">
        <a
          class="nav-link"
          [href]="lang === 'de' ? '/impressum' : '/imprint'"
          >{{ "footer.imprint" | translate }}</a
        >
        <a
          class="nav-link"
          [href]="lang === 'en' ? '/datenschutz' : '/privacy'"
          >{{ "footer.privacy" | translate }}</a
        >
      </nav>
    </div>
    <div class="col-md-3">
      Handcraft with <fa-icon [icon]="heartIcon"></fa-icon> in Bavaria
    </div>
  </div>
</div>
