<div
  class="modal fade show"
  id="shareCountdown"
  tabindex="-1"
  role="dialog"
  aria-labelledby="saveShareLabel"
  style="display: none"
  aria-hidden="true"
  #shareCountdown
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="saveShareLabel">
          {{ "webapp.shareCountdown" | translate }}
        </h1>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
      @if(countdownService.countdown().shortUrl) {
      <div class="modal-body">
        <p innerHTML="{{ 'webapp.share.text' | translate }}"></p>
        <h4>
          <a [href]="url + countdownService.countdown().shortUrl">{{
            url + countdownService.countdown().shortUrl
          }}</a>
        </h4>
      </div>
      } @else {

      <div class="modal-body">
        <p innerHTML="{{ 'webapp.share.error' | translate }}"></p>
      </div>
      }
      <div class="modal-footer">
        <button
          (click)="closeModal()"
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
        >
          {{ "webapp.buttons.cancel" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
