import { Component, ElementRef, ViewChild } from '@angular/core';
import { CountdownService } from '../../services/countdown.service';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-share-modal',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './share-modal.component.html',
  styleUrl: './share-modal.component.scss',
})
export class ShareModalComponent {
  @ViewChild('shareCountdown') shareCountdown: ElementRef | undefined;
  shareCountdownModal: bootstrap.Modal | undefined;

  url = environment.url;

  constructor(public countdownService: CountdownService) {}

  closeModal() {
    if (this.shareCountdownModal) {
      this.shareCountdownModal.hide();
    } else {
      console.error('this.shareCountdownModal is undefined');
    }
  }
}
